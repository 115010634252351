import React, { useState, useEffect } from "react";
import { Admin, Resource } from "react-admin";
import { ListGuesser, EditGuesser } from "react-admin";
import { APIUrl } from "./common/apiClient";
import VSDataProvider from "./providers/VSDataProvider";
 import polyglotI18nProvider from "ra-i18n-polyglot";

import "./App.css";

import authProvider from "./providers/authProvider";
import themeReducer from "./common/themeReducer";
import { Login, Layout } from "./layout";
import { Dashboard } from "./dashboard";
import customRoutes from "./routes";
import englishMessages from "./i18n/en";
import roletypes from './screens/roletypes';

import users from './screens/users';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import configs from './screens/configs';


import looksup from "./screens/lookup";
import country from "./screens/country";
import state from "./screens/state";
import district from "./screens/district";
import city from "./screens/city";
import courses from "./screens/courses";
import subjects from "./screens/subjects";
import events from "./screens/events";
import blogposts from "./screens/blogposts";
import testimonials from "./screens/testimonials";
import teams from "./screens/teams";
import news from "./screens/news";
import learningpost from "./screens/learningpost";
import pageContent from "./screens/pagecontent1";
import pagecontent1 from "./screens/pagecontent1";
import services from "./screens/services";
import userCourses from "./screens/userCourses";

import tests from "./screens/test";
import questions from "./screens/question";
import testquestionoptions from "./screens/questionoption";

const i18nProvider = polyglotI18nProvider((locale) => {
  if (locale === "fr") {
    return import("./i18n/fr").then((messages) => messages.default);
  }

  // Always fallback on english
  return englishMessages;
}, "en");

const App = () => {
  const [dataProvider, setDataProvider] = useState({});
  const [rights, setRights] = useState([]);
  const [resources, setResources] = useState<any[]>([]);

  let menu = localStorage.getItem("menu");
  const SetMenuData = () => {
    let menuData = [];
    if (menu) {
      menuData = JSON.parse(menu);
    }
    //console.log(menuData, "===menuData===");
    setRights(menuData)
  }

  const checkModulePermissions = (moduleurl: string) => {
    if (!rights) return false;
    if (rights.length == 0) return false;
    let index = rights.findIndex((item: any) => item.NavigateUrl == `/${moduleurl}`);
    if (index >= 0)
      return true;
    return false;
  }

  const addResourceforUser = (resource: any, constiner: any[], checkForPermission = true) => {
    if (!checkForPermission) {
      constiner.push({ ...resource });
      return;
    }
    if (checkModulePermissions(resource.name))
      constiner.push({ ...resource });
  }
  const setAvailableResources = () => {
    const container: any[] = [];

    addResourceforUser({ name: "states", ...state }, container, false);
    addResourceforUser({ name: "districts", ...district }, container, false);
    addResourceforUser({ name: "cities", ...city }, container, false);
        
    addResourceforUser({ name: "role-types", ...roletypes }, container,false);
    addResourceforUser({ name: "configs", ...configs }, container);
    
    addResourceforUser({ name: "users", ...users }, container,false);


    addResourceforUser({ name: "email-logs", list: ListGuesser, edit: EditGuesser }, container);
    addResourceforUser({ name: "configdets", list: ListGuesser, edit: EditGuesser }, container);
    // addResourceforUser({ name: "lookups", list: ListGuesser, edit: EditGuesser }, container, false);
    
    
    setResources(container);
  }
  useEffect(() => {
    let provider = VSDataProvider(APIUrl);
    setDataProvider(provider);
  }, []);

  useEffect(() => {
    if (!menu) return;
    SetMenuData();
  }, [menu]);

  useEffect(() => {
    if (!rights) return;
    setAvailableResources();
  }, [rights]);



  return (
    <>
      <Admin
        title=""
        dataProvider={dataProvider}
        customReducers={{ theme: themeReducer }}
        customRoutes={customRoutes}
        authProvider={authProvider}
        dashboard={Dashboard}
        loginPage={Login}
        layout={Layout}
        i18nProvider={i18nProvider}
      >
        {resources && resources.map(res => (
          <Resource name={res.name} list={res.list} create={res.create} edit={res.edit} />
        ))}


        <Resource name="users" {...users} /> 

        <Resource name="role-types" {...roletypes} />
        <Resource name="configs" {...configs} />
        
        <Resource name="countries" {...country} />
        
        

        <Resource name="configdets" list={ListGuesser} edit={EditGuesser} />
        <Resource name="lookups" {...looksup} />
        <Resource name="modules" list={ListGuesser} edit={EditGuesser} /> 
        <Resource name="subjects" {...subjects} />
        <Resource name="events" {...events} />
        <Resource name="blog-posts" {...blogposts} />
        <Resource name="testimonials" {...testimonials} /> 
        <Resource name="teams" {...teams} /> 
        <Resource name="news" {...news} /> 
        <Resource name="learning-posts" {...learningpost} /> 
        <Resource name="courses" {...courses} />
        <Resource name="content-page-configs" {...pagecontent1} />
        <Resource name="services" {...services} />
        <Resource name="user-courses" {...userCourses} />
        <Resource name="course-module-topic-chapter-files" list={ListGuesser} edit={EditGuesser} /> 
        <Resource name="course-module-topic-chapter-types" list={ListGuesser} edit={EditGuesser} />
        <Resource name="course-module-topic-chapters" list={ListGuesser} edit={EditGuesser} />
        <Resource name="course-module-topic-types" list={ListGuesser} edit={EditGuesser} />
        <Resource name="course-module-topics" list={ListGuesser} edit={EditGuesser} />
        <Resource name="course-modules" list={ListGuesser} edit={EditGuesser} /> 
        <Resource name="test-questions" {...questions}/>
        <Resource name="test-question-options" {...testquestionoptions}/>
        
      </Admin>
      <ToastContainer />
    </>
  );
};

export default App;
