import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";

  
export const ActivityEdit = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Edit"); 
  
  return (
    <Edit {...propsObj}  >
      
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput source="subjects" label="Subject Name"  fullWidth={true}  validate={[required()]} /> 
       <TextInput source="subjectDet" label="Subject Det"  multiline fullWidth={true} /> 
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>  
      </SimpleForm>
      
    </Edit>
  );
};
