import React from "react";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { Authorizer } from "../../common/Authorizer";
import Button from '@material-ui/core/Button';
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,AutocompleteInput,useRefresh
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";
import * as apiClient from "../../common/apiClient";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
     
    
        
        
        <ReferenceInput perPage={25} sort={{ field: 'userName', order: 'ASC' }} filterToQuery={searchText => ({ 'userName~like': searchText })}
      label="Candidate Email" source="userId" reference="users" fullWidth={true} >
      <AutocompleteInput optionText="userName" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

    <ReferenceInput perPage={25} sort={{ field: 'name', order: 'ASC' }} filterToQuery={searchText => ({ 'name~like': searchText })}
      label="Course " source="courseId" reference="courses" fullWidth={true} >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
    </ReferenceInput>

  </Filter>
);


export const ActivityList = (props) => { 
  const refresh = useRefresh();
  let propsObj = Authorizer(props,"List");  
  propsObj.hasCreate=false
  propsObj.hasEdit=false
  propsObj.hasDelete=false
  propsObj.bulkActionButtons = false;
  const approveRecord = (record) => {
    var userResponse = window.confirm("Are you sure, you want to Approve the record?");
    if(!userResponse)
        return;
    let data={...record};
    data.isApproved=true;
    
    // let m = moment(new Date());
      let userId;
      userId = localStorage.getItem("userId");
      if (userId) {
        userId = parseInt(userId);
      }
      data.approvedBy=userId;
      data.modifyBy=userId;
    apiClient.updateEntiyRecord("user-courses",record.id,data).then(res => {
         
        refresh();
    }).catch(err => {
        
        
    });
  }
  
  const CustomAction = (props) => {
    let { record } = props;
    if (props.record.isApproved== null ||props.record.isApproved== false ) {
      return (
        <div style={{ display: "flex" }}>
           <Button  color="primary" onClick={()=>approveRecord(props.record)}
                size="medium"> Approve</Button>
        </div>
      )
    }
    else
    {
      return null
    }
     
  }
  return (
    <List  {...propsObj} exporter={propsObj.hasExport}   filters={<Filters />}  >
      <Datagrid rowClick={false} >
        {/* {propsObj.hasShow && !propsObj.hasEdit && <ShowButton />}
        {propsObj.hasShow && propsObj.hasEdit && <EditButton />}
        {propsObj.hasDelete && <CDeleteButton {...props}/>} */}
       <CustomAction label="" source="id" />   
        <ReferenceField source="createdBy" reference="users" label="Candidate Email"  link={false}>
                <TextField source="userName" />
        </ReferenceField>
        <ReferenceField source="userId" reference="users" label="Candidate "  link={false}>
                <TextField source="name" />
        </ReferenceField>
        <ReferenceField source="courseId" reference="courses" label="Course"  link={false}>
                <TextField source="name" />
        </ReferenceField>
        <BooleanField source="isApproved" label="Approved"/>
        
        <BooleanField source="isActive" label="Active"/>
       
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
