import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  Show
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import RichTextInput from 'ra-input-rich-text';
export const ActivityShow = (props) => {
    let propsObj = Authorizer(props,"");  
  return (
    <Show {...propsObj}   >
      
      <SimpleForm  toolbar={<FormToolbar {...propsObj} hideSave={true}/>} variant="outlined"  >
      <TextInput source="name" label=" Name"  fullWidth={true}  validate={[required()]} /> 
       
       <RichTextInput source="description" label="Description" />
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true}/>  
      
      </SimpleForm>
    </Show>
  );
};
