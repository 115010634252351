import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
export const ActivityEdit = (props) => {
  
  let propsObj = Authorizer(props,"Edit"); 
  
  return (
    <Edit {...propsObj}  >
      
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput source="code" label="Code"  fullWidth={true}  validate={[required()]} disbled/>
       <TextInput source="name" label="Name"  fullWidth={true}  validate={[required()]}/> 
      <ReferenceInput source="stateId" reference="states"  validate={[required()]} fullWidth={true} link={false} label="State" perPage={2000} sort={{ field: 'name', order: 'ASC' }}>
                        <SelectInput source="name" />
                    </ReferenceInput>
       
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
      </SimpleForm>
      
    </Edit>
  );
};
