import React, { useState, useCallback } from 'react';
import RichTextInput from 'ra-input-rich-text'; 
import {
    required, minLength, maxLength, minValue, maxValue,
    number, regex, email, choices,
    TabbedForm, FormTab, DeleteButton, useRefresh, AutocompleteInput,
    Edit, SimpleForm, TextInput, RichTextField,
    ReferenceInput, SelectInput, EditButton, NumberField,
    DateInput, BooleanInput, NumberInput, ImageInput, ImageField,
    ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, FormDataConsumer
} from 'react-admin';
import { useForm } from 'react-final-form';
import { containerStyles } from "../../common/formStyles";

export const CourseFields = ({ formData, ...rest }) => {
    let clientId;
    clientId = localStorage.getItem("ClientId");
    if (clientId) {
     clientId = parseInt(clientId);
    }
    else
    {
     clientId=null
    }
    const form = useForm();
    const classes = containerStyles({});
    return (
        <div  >
            <ReferenceInput label="Course"  variant="outlined"  validate={ required() }
             source="courseId" reference="courses" fullWidth={ true } className={  classes.one_three_input }
             onChange={(v) => {                     
                form.change("courseTopicTypeId", null);
                form.change("CourseTopicID", null);
                
            }}
             >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
            
   
            <ReferenceInput variant="outlined" label="Course Topic Type" source="courseTopicTypeId" reference="course-module-topic-types"
                onChange={(v) => {                     
                    form.change("CourseTopicID", null);
                    
                }}
                filter={{ courseId: formData.courseId?formData.courseId:0 }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.two_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <ReferenceInput variant="outlined" label="Course Topic " source="courseTopicId" reference="course-module-topics"
                 
                filter={{ courseId: formData.courseId?formData.courseId:0 }}
                sort={{ field: 'name', order: 'ASC' }}
                fullWidth={true} perPage={500} className={classes.last_three_input}>
                <SelectInput optionText="name" />
            </ReferenceInput>

            <NumberInput source="noOfChoice" label="No of Choices"  validate={required()} fullWidth={true} className={classes.one_three_input} variant="outlined" />
                <NumberInput source="questionNumber" label="Question Number" validate={required()} fullWidth={true} className={classes.two_three_input} variant="outlined"/>
                <RichTextInput source="question" label="Question"variant="outlined"  />              
                <ImageInput source="logoUrl" label="Icon" accept="image/*" className="logourl">
                    <ImageField source="logoUrl" title="Icon" />
                </ImageInput>
                <BooleanInput initialValue={true} source="mustanswer" validate={required()}  label="Must Answer" />

            
        </div>
    )
}
