import React, { useEffect, useCallback, useState } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    FormDataConsumer,
    Edit, SimpleForm, TextInput, Create,
    DateInput, BooleanInput, NumberInput, FileInput, FileField, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton, SelectInput
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import moment from 'moment';
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { makeStyles } from '@material-ui/core/styles';
import { useStyles } from "../../common/formStyles";

const ModuleChapterFileEdit = props => {
    const classes = useStyles({});




    const handleSubmit = async (formdata) => {
        let data = formdata;
        if (data.hasOwnProperty("fileUrlup")) {
            if (data.fileUrlup != null) {
                let response = await apiClient.UploadFile("fileUrlup", data.fileUrlup);
                if (response != null) {
                    debugger

                    data.iconFile = response;


                    let m = moment();
                    const userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0;

                    delete data.fileUrlup
                    // Call your API to add entity record
                    data.modifyDate = m.toISOString();
                    data.modifyBy = userId;
                    apiClient.updateEntiyRecord("course-module-topic-chapter-files", data.id, data)
                        .then(res => {
                            console.log(res);
                            props.onClose();
                        })
                        .catch(err => {
                            console.error(err);
                        });

                }
            }
        }
        else {
            let m = moment();
            const userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0;

            data.modifyDate = m.toISOString();
            data.modifyBy = userId;
            apiClient.updateEntiyRecord("course-module-topic-chapter-files", data.id, data)
                .then(res => {
                    console.log(res);
                    props.onClose();
                })
                .catch(err => {
                    console.error(err);
                });

        }


    }
    return (
        <div style={{ maxHeight: '80vh', overflow: 'auto' }}> {/* Container with fixed height and overflow */}
        <Edit {...props} id={props.record.id} resource="course-module-topic-chapter-files" redirect={false}>
            
                <SimpleForm
                    onSubmit={handleSubmit}
                    toolbar={<PopupCreateToolbar label="Save" onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    variant="outlined">
                    <ReferenceInput
                        label="Modules"
                        variant="outlined"
                        source="courseModuleId"
                        reference="course-modules"
                        filter={{ courseId: props.courseId ? props.courseId : 0 }}
                        sort={{ field: 'name', order: 'asc' }}
                        validate={required()}
                        fullWidth={true}
                        formClassName={classes.one_4_input}
                    >
                        <SelectInput optionText="name" variant="outlined" fullWidth={true}
                            formClassName={classes.one_4_input} />
                    </ReferenceInput>
                    <ReferenceInput
                        variant="outlined"
                        label="Topic Group"
                        source="courseModuleTopicTypeId"
                        reference="course-module-topic-types"
                        filter={{ courseId: props.courseId }}
                        sort={{ field: 'name', order: 'asc' }}
                        validate={required()}
                        fullWidth={true}
                        formClassName={classes.two_4_input}


                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <ReferenceInput
                        label="Topic "
                        source="courseModuleTopicId"
                        reference="course-module-topics"
                        filter={{ courseId: props.courseId }}
                        sort={{ field: 'name', order: 'asc' }}
                        validate={required()}
                        fullWidth={true}
                        formClassName={classes.three_4_input}
                        variant="outlined"

                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <ReferenceInput
                        label="Chapter Group "
                        source="courseModuleTopicChapterTypeId"
                        reference="course-module-topic-types"
                        filter={{ courseId: props.courseId }}
                        sort={{ field: 'name', order: 'asc' }}
                        validate={required()}
                        fullWidth={true}
                        formClassName={classes.last_4_input}
                        variant="outlined"

                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>

                    <ReferenceInput
                        label="Chapter Topic "
                        source="courseModuleTopicChapterId"
                        reference="course-module-topic-chapters"
                        filter={{ courseId: props.courseId }}
                        sort={{ field: 'name', order: 'asc' }}
                        validate={required()}
                        fullWidth={true}
                        formClassName={classes.one_4_input}
                        variant="outlined"

                    >
                        <SelectInput optionText="name" />
                    </ReferenceInput>
                    <TextInput fullWidth={true} source="code" label="Code" validate={required()} formClassName={classes.two_4_input} variant="outlined" />
                    <TextInput fullWidth={true} source="name" label="Name" validate={required()} formClassName={classes.three_4_input} variant="outlined" />
                    <ReferenceInput
                        label="File Type"
                        source="fileTypeId"
                        reference="lookups"
                        validate={required()}
                        sort={{ field: 'name', order: 'asc' }}
                        filter={{ type: 'DT' }}
                        fullWidth={true}
                        formClassName={classes.last_4_input} variant="outlined">
                        <SelectInput optionText="name" variant="outlined" />
                    </ReferenceInput>

                    <RichTextInput fullWidth={true} source="description" label="Description" variant="outlined"       toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}/>
                    {/* File Input */}
                    <FileInput
                        source="fileUrlup"
                        formClassName={classes.one_4_input}
                        label="Icon File"

                        className="logourl"
                        variant="outlined"
                    >
                        <FileField source="fileUrlup" title="File" variant="outlined" />
                    </FileInput>
                    <FileField source="iconFile" title="File" formClassName={classes.two_4_input} variant="outlined" />
                    <NumberInput source="durationInSecond" label="Duration In Second" validate={required()} formClassName={classes.three_4_input} variant="outlined" />


                    <BooleanInput source="isActive" label="Is Active" formClassName={classes.last_4_input} />
                    <FileInput
                        source="chapterFileUp"
                        formClassName={classes.one_4_input}
                        label="Chapter File"

                        className="logourl"
                        variant="outlined"
                    >
                        <FileField source="chapterFileUp" title="Chapter File " variant="outlined" />
                    </FileInput>
                    <FileField source="chapterFile" title="Chapter File " formClassName={classes.two_4_input} variant="outlined" />
                    <NumberInput source="displayOrder" label="Display Order"  validate={required()}  formClassName={classes.last_4_input}/>


                </SimpleForm>
            
        </Edit>
        </div>
    );
}
export default ModuleChapterFileEdit;