import React from "react";
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import { Authorizer } from "../../common/Authorizer";
import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  ShowButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField
} from "react-admin";
import CDeleteButton from "../../common/CDeleteButton";


const Filters = (props) => (
  <Filter {...props} variant="outlined">
     <SearchInput
      variant="outlined"
      label="Code"
      source="code~like"
      alwaysOn
    />
    <SearchInput
      variant="outlined"
      label=" Name"
      source="subjects~like"
      alwaysOn
    />
    
  </Filter>
);
export const ActivityList = (props) => { 
  let propsObj = Authorizer(props,"Edit");  
  return (
    <List  {...propsObj} exporter={propsObj.hasExport}   filters={<Filters />}  >
      <Datagrid rowClick={false} >
        {propsObj.hasShow && !propsObj.hasEdit && <ShowButton />}
        {propsObj.hasShow && propsObj.hasEdit && <EditButton />}
        {propsObj.hasDelete && <CDeleteButton {...props}/>}
        
        <TextField source="name" label=" Name " /> 
        <BooleanField source="isActive" label="Active"/>
        <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
