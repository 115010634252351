import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";
import { Authorizer } from "../../common/Authorizer";
import CDeleteButton from "../../common/CDeleteButton";

const Filters = props => (
    <Filter {...props} variant="outlined">
        <SearchInput variant="outlined" label="State Name" source="stateName~like" alwaysOn />
    </Filter>
);
export const StateList = (props) => {
  const propsObj = Authorizer(props,"List");
  return (
    <List {...propsObj} filters={<Filters />}  >
      <Datagrid >
      {propsObj.hasEdit && <EditButton />}
        {propsObj.hasDelete && <CDeleteButton {...props}/>}
        <TextField source="code" label="State Code" />
        <TextField source="name" label="State Name" />
        <BooleanField source="isActive" label="Active"/>
        <ReferenceField source="countryId" reference="countries" link={false} label="Country" >
                        <TextField source="name" />
                    </ReferenceField>
                    <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DisplayDateTime  source="modifyDate" label="Updated Date" locales="fr-FR" />
      </Datagrid>
    </List>
  );
};
