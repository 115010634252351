import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
export const StateCreate = (props) => {
  const propsObj = Authorizer(props,"Create");
  return (
    <Create title="State Edit" {...propsObj}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      > 
      <ReferenceInput source="countryId" reference="countries"  fullWidth={true} link={false} label="Country" >
                        <SelectInput source="name" />
                    </ReferenceInput>
        <TextInput source="code" fullWidth={true} validate={[required()]} />
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>
      </SimpleForm>
    </Create>
  );
};
