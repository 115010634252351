import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput, ImageInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import RichTextInput from 'ra-input-rich-text';
import CustomImageDisplay from '../../common/custom-image-display';

export const PageContentEdit = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit = (p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = (p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <Edit {...propsObj} title="Page Content Edit" undoable={false}>
      <SimpleForm toolbar={<FormToolbar {...propsObj} />} variant="outlined">

        <TextInput source="sectionType" label="Section" fullWidth={true} validate={[required()]} />
        <TextInput source="propertyName" label="Name" fullWidth={true}/>
        <TextInput source="propertyLabel" lable="Heading" fullWidth={true}/>
        <RichTextInput source="propertyDescription" fullWidth={true} />
        <TextInput source="propertyValue" label="Additional Value" fullWidth={true}/>
        <div>
       { <ImageInput source="imageUrlup" fullWidth={true} 
                            label="Icon File" accept="image/*" className="logourl">
                            <ImageField source="imageUrlup" title="Image" />
                        </ImageInput>}
                        
       </div>
       
        <CustomImageDisplay/>
      </SimpleForm>
    </Edit>
  );
};
