import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
export const CountryEdit = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Edit"); 
  return (
    <Edit  {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input} disabled/>
        <TextInput source="name" validate={[required()]}  formClassName={classes.two_three_input}/>
        <BooleanInput source="isActive" validate={[required()]} initialValue={true} label="Active" formClassName={classes.last_three_input}  />
                    
        
        </SimpleForm>
    </Edit>
  );
};
