export const ROLETYPE_PICKER = 24;
export const ROLETYPE_PACKER = 27;
export const ROLETYPE_VNA = 26;
export const ROLETYPE_INBOUND = 29;
export const ROLETYPE_AUDIT = 30;

export const TASK_TYPE_INBOUND = 19;
export const TASK_TYPE_AUDIT = 20;

export const TASK_STATUS_CREATED = 9;
export const TASK_STATUS_COMPLETED = 14;
export const TASK_STATUS_ASSIGNED = 10;
export const TASK_STATUS_PICKED = 11;
export const TASK_STATUS_PICKING_IN_PROGRESS = 12;
export const TASK_STATUS_PACKAING_IN_PROGRESS = 13;

export const ACTIVITY_TYPE_GP = 22;
export const ACTIVITY_TYPE_RP = 23;
export const ACTIVITY_TYPE_NP = 21;

export const ITEMVIEW = "ITEMWISE";
export const PICKBINVIEW = "PICKBINVIEW";
export const CARTONVIEW = "CARTONVIEW";


export const PICK_REVIEW = "REVIEW";
export const PICK_RELEASEPALLET = "RELEASEPALLET";
export const PICK_REVIEW_RELEASEPALLET = "REVIEW_RELEASEPALLET";
export const PICK_RELEASEPICKBIN = "RELEASEPICKBIN";
export const PICK_RELEASEPICKBIN_RELEASEPALLET = "RELEASEPICKBIN_RELEASEPALLET";
export const PICK_REVIEW_NOPICKBIN = "REVIEW_NOPICKBIN";
export const PICK_PICKBINSCANNED = "PICKBINSCANNED";
export const PICK_ITEMSCANNED = "ITEMSCANNED";
export const AUDDECISIONADD= "AUDDECISIONADD";
export const AUDDECISIONHND= "AUDDECISIONHND";
