import React from 'react';
import {
    Create,
    SimpleForm,
    TextInput,
    ReferenceInput,
    SelectInput,
    FileInput,
    FileField,
    BooleanInput, required,NumberInput
} from 'react-admin';
import moment from "moment";
import * as apiClient from "../../common/apiClient";
import PopupCreateToolbar from "../../common/PopupCreateToolbar";
import { useStyles } from "../../common/formStyles";
import RichTextInput from 'ra-input-rich-text';
export const ModuleChapterTopicTypeCreate = (props) => {
    const classes = useStyles({});
    // Initialize initial form values
    const initialValues = {
        isActive: true,
        isVisible: true,
        courseModuleId:props.courseModuleId,
        courseModuleTopicTypeId:props.courseModuleTopicTypeId,
        courseModuleTopicId:props.courseModuleTopicId,
        
    };

    // Handle form submission
    const handleSubmit = async (formData) => {
        debugger

        let iconFile = "";

        if (formData.hasOwnProperty("fileUrlup")) {
            if (formData.fileUrlup != null) {
                let response = await apiClient.UploadFile("fileUrlup", formData.fileUrlup);
                if (response != null) {
                    debugger

                    iconFile = response;

                    const date = formData.date ? moment(formData.date).format() : null;
                    let m = moment();
                    const userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0;
                    const data = {
                        courseId: parseInt(props.courseId),
                        courseModuleId: parseInt(formData.courseModuleId),
                        courseModuleTopicTypeId: parseInt(formData.courseModuleTopicTypeId),
                        courseModuleTopicId: parseInt(formData.courseModuleTopicId),
                        displayOrder: parseInt(formData.displayOrder),

                        code: formData.code,
                        name: formData.name,
                        description: formData.description,
                        durationInSecond: parseInt(formData.durationInSecond),
                        isActive: formData.isActive,
                        iconFile: iconFile,
                        fileTypeId: parseInt(formData.fileTypeId),
                        createdBy: userId,
                        createdDate: m.toISOString(),
                        modifyBy: userId,
                        modifyDate: m.toISOString(),
                    };

                    // Call your API to add entity record

                    apiClient.addEntiyRecord("course-module-topic-chapter-types", data)
                        .then(res => {
                            console.log(res);
                            props.onClose();
                        })
                        .catch(err => {
                            console.error(err);
                        });

                }
            }
        }
else
{
    const date = formData.date ? moment(formData.date).format() : null;
    let m = moment();
    const userId = localStorage.getItem("userId") ? parseInt(localStorage.getItem("userId")) : 0;
    const data = {
        courseId: parseInt(props.courseId),
        courseModuleId: parseInt(formData.courseModuleId),
        courseModuleTopicTypeId: parseInt(formData.courseModuleTopicTypeId),
        courseModuleTopicId: parseInt(formData.courseModuleTopicId),
        displayOrder: parseInt(formData.displayOrder),

        code: formData.code,
        name: formData.name,
        description: formData.description,
        durationInSecond: parseInt(formData.durationInSecond),
        isActive: formData.isActive,
   
        fileTypeId: parseInt(formData.fileTypeId),
        createdBy: userId,
        createdDate: m.toISOString(),
        modifyBy: userId,
        modifyDate: m.toISOString(),
    };

    // Call your API to add entity record

    apiClient.addEntiyRecord("course-module-topic-chapter-types", data)
        .then(res => {
            console.log(res);
            props.onClose();
        })
        .catch(err => {
            console.error(err);
        });

}



    };

    return (
        <Create {...props}>
            <SimpleForm
                initialValues={initialValues}
                onSubmit={handleSubmit}
                toolbar={<PopupCreateToolbar onSave={handleSubmit} label="Save" onClose={props.onCancel} />}
                variant="outlined" >
                <ReferenceInput
                    label="Modules"
                    source="courseModuleId"
                    reference="course-modules"
                    filter={{ courseId: props.courseId ? props.courseId : 0 }}
                    sort={{ field: 'name', order: 'asc' }}
                    validate={required()}
                    fullWidth={true}
                    formClassName={classes.one_three_input} 
                >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                <ReferenceInput
                    label="Topic Group"
                    source="courseModuleTopicTypeId"
                    reference="course-module-topic-types"
                    filter={{ courseId: props.courseId }}
                    sort={{ field: 'name', order: 'asc' }}
                    validate={required()}
                    fullWidth={true}
                    formClassName={classes.two_three_input} 

                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <ReferenceInput
                    label="Topic "
                    source="courseModuleTopicId"
                    reference="course-module-topics"
                    filter={{ courseId: props.courseId }}
                    sort={{ field: 'name', order: 'asc' }}
                    validate={required()}
                    fullWidth={true}
                    formClassName={classes.last_three_input} 

                >
                    <SelectInput optionText="name" />
                </ReferenceInput>

                <TextInput fullWidth={true} source="code" label="Code" validate={required()} formClassName={classes.one_three_input} />
                <TextInput fullWidth={true} source="name" label="Name" validate={required()}  formClassName={classes.two_three_input} />
                <ReferenceInput
                    label="File Type"
                    source="fileTypeId"
                    reference="lookups"
                    validate={required()}
                    sort={{ field: 'name', order: 'asc' }}
                    filter={{ type: 'DT' }}
                    fullWidth={true}
                     formClassName={classes.last_three_input}  >
                    <SelectInput optionText="name" />
                </ReferenceInput>
                
                <RichTextInput fullWidth={true} source="description" label="Description"       toolbar={[
          [{ 'header': [1, 2, 3, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [{ 'list': 'ordered' }, { 'list': 'bullet' }],
          ['image', 'link', 'video'],
          ['clean']
        ]}/>
                {/* File Input */}
                <FileInput
                    source="fileUrlup"
                    formClassName={classes.one_4_input}
                    label="Icon File"
                     className="logourl"
                >
                    <FileField source="fileUrlup" title="File" />
                </FileInput>
                <FileField source="iconFile" title="File" formClassName={classes.two_4_input}  />
                <NumberInput source="durationInSecond" label="Duration In Second"  validate={required()}  formClassName={classes.three_4_input}/>
                
                {/* Other input fields */}
                
                <BooleanInput source="isActive" label="Is Active" formClassName={classes.three_4_input}/>
                <NumberInput source="displayOrder" label="Display Order"  validate={required()}  formClassName={classes.last_4_input}/>
            </SimpleForm>
        </Create>
    );
};
