import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,DateInput
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";

  
export const ActivityEdit = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Edit"); 
  
  return (
    <Edit {...propsObj}  >
      
      <SimpleForm redirect="list" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
        
      <TextInput source="name" label="Name"  fullWidth={true}  validate={[required()]} />
       
       <TextInput source="description" label="Description"  multiline fullWidth={true}  validate={[required()]} /> 
       
       <div>
       { <ImageInput source="imageUrlup" formClassName={classes.one_4_input}
                            label="Icon File" accept="image/*" className="logourl">
                            <ImageField source="imageUrlup" title="Image" />
                        </ImageInput>}
                        
       </div>
       <ImageField source="imageUrl" title="Image" formClassName={classes.twice_4_input}/>

       <div>
       { <ImageInput source="imageUrlup1" formClassName={classes.one_4_input}
                            label="Icon File" accept="image/*" className="logourl">
                            <ImageField source="imageUrlup1" title="Image" />
                        </ImageInput>}
                        
       </div>
       <ImageField source="imageUrl1" title="Image" formClassName={classes.twice_4_input}/>
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} formClassName={classes.last_4_input}/>
      </SimpleForm>
      
    </Edit>
  );
};
