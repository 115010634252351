import React from 'react';
import { Route } from 'react-router-dom';
import Configuration from './configuration/Configuration';
import {ChangePassword} from "./screens/changepassword/ChangePassword";


export default [
    <Route exact path="/configuration" render={() => <Configuration />} />,   
    <Route exact path="/changepassword" render={(props) => <ChangePassword {...props}/>}  />,
    
    
];
