import React, { useState, useEffect, useCallback } from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,useRefresh,
    email, ReferenceManyField, Datagrid, ReferenceField,
    choices, FormTab, TabbedForm, TextField,
    Edit, SimpleForm, TextInput, SelectInput,
    DateInput, BooleanInput, NumberInput, ReferenceInput,
    ImageInput, ImageField, SaveButton, Toolbar, DeleteButton
} from 'react-admin';
import { DisplayDate, DisplayDateTime } from '../../common/DisplayDate';
import RichTextInput from 'ra-input-rich-text';
import { Drawer } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import CDeleteButton from "../../common/CDeleteButton";
import 'react-accessible-accordion/dist/fancy-example.css';
import * as apiClient from "../../common/apiClient";
import CreateToolbar from "../../common/PopupCreateToolbar";
import Switch from '@material-ui/core/Switch';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import {
    BrowserRouter as Router,
    Route,
    Link,
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import { RoleTypesEditModule } from "./RoleTypesEditModule";
import { Authorizer } from "../../common/Authorizer";
import RoleStations from './RoleStations';

const myStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    list: {
        flexGrow: 1,
        transition: theme.transitions.create(['all'], {
            duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
    },
    listWithDrawer: {
        marginRight: 300,
    },
    drawerPaper: {
        zIndex: 100,
        marginTop: 50,
        width: 850,
    },
}));

const useStyles = makeStyles(theme => ({
    subjectid: {
        display: 'inline-block'
    },
    title: {
        display: 'inline-block',
        marginLeft: '20px',
        width: '300px'
    },
    root: {
        width: '100%',
    },
    heading: {
        fontSize: 15,
    },
}));


export const MyCheckbox = (props) => {
    console.log("Checkbox Regenerated");
    let [checked, setChecked] = useState(true);
    let right = props.right;
    let category = props.category;
    let name = props.name;
    const handleOnChange = (e) => {
        if (checked) {
            setChecked(false);
            props.onChange(0, right, category, name);
        } else {
            props.onChange(1, right, category, name);
            setChecked(true);
        }
    }
    useEffect(() => {
        if (props.value == 1)
            setChecked(true);
        else
            setChecked(false);
    }, [props.value]);
    return (
        <FormControlLabel
            control={<Checkbox checked={checked}
                onChange={handleOnChange}
                name={name} />}
            label={name}
        />
    )
}

export const RoleDetailsForm = (props) => {
    const classes = useStyles();

    let rights = props.rights;
    let categories = props.categories;
    let record = props.record;

    //console.log("Rerender : ");
    //console.log(rights);

    const TopLevelSelectAllItems = (e, category) => {
        console.log(category);
    }
    const flagAllrights = (e, item, category) => {
        let localRights = { ...rights };
        let mod = localRights[category];
        let modArray = [...mod];
        let index = modArray.findIndex((inner, index) => inner.Module == item.Module);
        modArray[index] = item;
        localRights[category] = modArray;
        props.setRights(localRights);
        //console.log("flagAllrights - calling parent");
        //console.log(localRights);
        // }
    }
    const selectUnselectItem = (value, item, category, name) => {
        let localRights = rights;
        let mod = localRights[category];
        let modArray = [...mod];
        let index = modArray.findIndex(inner => inner.Module == item.Module);
        item[name] = value;
        modArray[index] = item;
        localRights[category] = modArray;
        props.setRights(localRights);
        //console.log("selectUnselectItem - calling parent");
        //console.log(localRights);
    }
    const getRolesForRoleId = () => {
        apiClient.getfunctionbyroletype(record.id).then((res) => {
            var data = res;
            var outObject = data.reduce(function (a, e) {
                let estKey = (e['ModuleCategory']);
                (a[estKey] ? a[estKey] : (a[estKey] = null || [])).push(e);
                return a;
            }, {});
            var keys = Object.keys(outObject);
            props.setCategories(keys);
            props.setRights(outObject);
        }).catch((err) => {
            console.log(err);
        });
    }
    useEffect(() => {
        getRolesForRoleId();
    }, []);
    return (
        <div className="role-details">
            <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} >
                {categories && categories.length > 0 && categories.map((item, index) => {
                    return (
                        <AccordionItem key={index} uuid={String(index)}>
                            <div className="header-parent">
                                <AccordionItemHeading className="accord-header">
                                    <AccordionItemButton>
                                        <div className="item-caption">{item}</div>
                                    </AccordionItemButton>
                                </AccordionItemHeading>
                                {/* <div className="item-switch"><Switch className="right-toggle"
                                    onChange={(e) => { TopLevelSelectAllItems(e, item); }}
                                    name="checkedA"
                                    inputProps={{ 'aria-label': 'secondary checkbox' }}
                                /></div> */}
                            </div>
                            <AccordionItemPanel>
                                <Accordion allowMultipleExpanded={true} allowZeroExpanded={true} preExpanded={["0", "1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "19"]}>
                                    {rights && rights[item] && rights[item].map((inner, rightIndex) => {
                                        return (
                                            <RoleTypesEditModule key={rightIndex} flagAllrights={flagAllrights} selectUnselectItem={selectUnselectItem} right={inner} category={item} rightIndex={rightIndex} />
                                        )
                                    })}
                                </Accordion>
                            </AccordionItemPanel>
                        </AccordionItem>
                    )
                })}
            </Accordion>
        </div>
    );
}

export const RoleTypesEdit = props => {
    const propsObj = Authorizer(props, "edit");
    const myclasses = myStyles({});
    const history = useHistory();
    const refresh = useRefresh();
    const [rights, setRights] = useState<any>({});
    const [categories, setCategories] = useState([]);
    const [isaddStation, setIsAddStation] = useState(false);


    const handleClose = useCallback(() => {
        setIsAddStation(false);
        refresh();
    }, []);

    const handleSubmit = (formdata) => {
        // console.log(formdata);
        //console.log(rights);
        // let date = (formdata.date) ? moment(formdata.date).format() : null;

        let finalData = [];
        categories.forEach(item => {
            finalData = finalData.concat(rights[item]);
        });

        let data = {
            role_id: formdata.id,
            role_name: formdata.roleName,
            role_type: formdata.roleType,
            defModuleID: formdata.defModuleID,
            rights: finalData
        }

        apiClient.updateRolerights(data).then(res => {
            if (res.result == "success") {
                history.push("/role-types");
            }
        }).catch(err => {
            console.log(err);
        });
    }

    return (
        <>
            <Edit
                {...propsObj} title="Role Types" redirect={false}
                successMessage="Role Type Updated" undoable={false}>
                <TabbedForm onSubmit={handleSubmit}
                    toolbar={<CreateToolbar onSave={handleSubmit} onClose={props.onClose} onCancel={props.onCancel} />}
                    variant="outlined">
                    <FormTab value="DETAILS" label="Role Detail">
                        <TextInput validate={required()} source="roleName" label="Role Name" />
                        <ReferenceInput label=" Role Type " source="roleType" reference="lookups" validate={[required()]}
                            sort={{ field: 'name', order: 'asc' }}
                            filter={{ type: 'ROLETYPE' }}
                        >
                            <SelectInput optionText="name" />
                        </ReferenceInput>
                        <ReferenceInput label=" Default Module " source="defModuleID" reference="modules" validate={[required()]}
                            sort={{ field: 'modName', order: 'asc' }}
                        >
                            <SelectInput optionText="modName" />
                        </ReferenceInput>
                        {<RoleDetailsForm rights={rights} categories={categories} setRights={setRights} setCategories={setCategories} />}
                    </FormTab>
                    <FormTab value="STATIONS" label="Stations">


                        <Button variant="contained" onClick={() => { setIsAddStation(true); }}
                            color="secondary">
                            Add Station
                        </Button>
                        <ReferenceManyField fullWidth={true}
                            addLabel={false}
                            sort={{ field: 'id', order: 'ASC' }}
                            reference="role-station-maps"
                            perPage={500}
                            target="roleTypeId">
                            <Datagrid>
                                <CDeleteButton {...props} />
                                <ReferenceField source="stationId" reference="stations" label="Station" link={false}>
                                    <TextField source="name" />
                                </ReferenceField>
                                <ReferenceField source="createdBy" reference="users" label="Created By" link={false}>
                                    <TextField source="userName" />
                                </ReferenceField>
                                <DisplayDateTime source="createdDate" label="Created Date" locales="fr-FR" />
                            </Datagrid>
                        </ReferenceManyField>

                    </FormTab>
                </TabbedForm>
            </Edit>
            <Drawer
                anchor="right"
                onClose={handleClose}
                classes={{ paper: myclasses.drawerPaper, }}
                open={isaddStation} >
                {isaddStation && <RoleStations
                    onClose={handleClose}
                    onCancel={handleClose}
                    roleTypeId={props.id}
                    {...props} />}
            </Drawer>
        </>
    )
};
