import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  Show
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
export const ActivityShow = (props) => {
    let propsObj = Authorizer(props,"");  
  return (
    <Show {...propsObj}   >
      
      <SimpleForm  toolbar={<FormToolbar {...propsObj} hideSave={true}/>} variant="outlined"  >
      <ReferenceInput source="stateId" reference="states"  validate={[required()]} fullWidth={true} link={false} label="State" >
                        <SelectInput source="name" />
                    </ReferenceInput>
       <TextInput source="code" label="Code"  fullWidth={true}  validate={[required()]}/>
       <TextInput source="name" label="Name"  fullWidth={true}  validate={[required()]}/> 
        <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} />
      </SimpleForm>
    </Show>
  );
};
