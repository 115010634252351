import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Edit, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput
} from 'react-admin';
import { useStyles } from "../../formStyles";
import RichTextInput from 'ra-input-rich-text';
import { parse } from "query-string";
import CustomLogoInputDisplay from "../../CustomImage";
import {CreateToolbar} from "../../common/CreateToolbar";

export const QuestionOptionEdit = props => {
    const classes = useStyles({});
    const { question_id: question_id_string } = parse(props.location.search);
    const question_id = question_id_string ? parseInt(question_id_string, 10) : "";

    const redirect = question_id ? `/testquestions/${question_id}/1` : "show";
    return (
        <Edit { ...props } title="Modify question option">
            <SimpleForm redirect={ false } variant="outlined" toolbar={ <CreateToolbar { ...props } /> }>

                <ReferenceInput disabled label="question" validate={ required() } source="testquestionid" reference="testquestions" fullWidth={ true }>
                    <SelectInput optionText="question" />
                </ReferenceInput>

                <NumberInput source="optionnumber" validate={ required() } label="Option Number" />
                <TextInput source="optiontitle" validate={ required() } label="Option Title" />
                <RichTextInput source="optiontext" label="Option Text" fullWidth={ true } />
                <ImageInput source="optionimage" label="Option Image" accept="image/*" className="logourl">
                    <ImageField source="optionimage" title="Icon" />
                </ImageInput>
                <CustomLogoInputDisplay field="optionimage" />
                <BooleanInput initialValue={ true } source="iscorrect" label="Is Correct" />
            </SimpleForm>
        </Edit>
    );
}