import React from "react";
import { JsonSchemaForm } from "react-jsonschema-form";

import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";
export const ActivityCreate = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Create"); 
  let tenantID = localStorage.getItem("TenantID");
  let facilityID = localStorage.getItem("FacilityID");
  let userID = localStorage.getItem("userId");
  let defaultValues = {
  //  tenantId: tenantID,
    // facilityId: facilityID,
    
    createdDate: new Date(),
    createdBy: userID,
    isActive: true
  };
  return (
    <Create {...propsObj}> 
      <SimpleForm redirect="list" initialValues={defaultValues}
        toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" > 
         <ReferenceInput label="Subject" source="subjectId"  fullWidth={true} reference="subjects" >
          <SelectInput optionText="subjects" validate={[required()]} />
        </ReferenceInput>
       <TextInput source="code" label="Code"  fullWidth={true}  validate={[required()]} formClassName={classes.one_three_input}/>
       <TextInput source="name" label="Name"  fullWidth={true}  validate={[required()]} formClassName={classes.two_three_input}/> 
       <TextInput source="description" label="Description"  multiline fullWidth={true}  validate={[required()]} formClassName={classes.last_three_input}/> 

       <TextInput source="author" label="Author" fullWidth={true}  validate={[required()]} formClassName={classes.one_three_input}/>
       <TextInput source="support" label="Support"   fullWidth={true}  validate={[required()]} formClassName={classes.two_three_input}/> 
       <TextInput source="about" label="About"  multiline fullWidth={true}  validate={[required()]} formClassName={classes.last_three_input}/> 



       <TextInput source="title" label="Title" fullWidth={true}  validate={[required()]} formClassName={classes.one_three_input}/>
       <TextInput source="publName" label="Publisher"   fullWidth={true}  validate={[required()]} formClassName={classes.two_three_input}/> 
       <TextInput source="edition" label="Edition"  fullWidth={true}  validate={[required()]} formClassName={classes.last_three_input}/> 

       <TextInput source="copyYear" label="Year"   fullWidth={true}  validate={[required()]} formClassName={classes.one_three_input}/> 
       <TextInput source="requirements" label="Requirements"   fullWidth={true}  validate={[required()]} formClassName={classes.two_three_input}/>  
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} formClassName={classes.last_three_input} />  
       <div>
       { <ImageInput source="iconFileup" formClassName={classes.one_4_input}
                            label="Icon File" accept="image/*" className="logourl">
                            <ImageField source="iconFileup" title="Icon" />
                        </ImageInput>}
                        <ImageField source="iconFile" title="Icon" />
       </div>
        
      </SimpleForm>
    </Create>
  );
};
