import React from "react";
import { DisplayDate } from '../../common/DisplayDate';

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectField,
} from "react-admin";

const Filters = (props) => (
  <Filter {...props} variant="outlined">
    <SearchInput variant="outlined" label="section Type" source="sectionType~like" alwaysOn />
    <SearchInput variant="outlined" label="Name" source="propertyName~like" alwaysOn />
    <SearchInput variant="outlined" label="Label" source="propertyLabel~like" alwaysOn />
    <SearchInput variant="outlined" label="Value" source="propertyValue~like" alwaysOn />
    

  </Filter>
);
export const PageContentList = (props) => {

  let propsObj = { ...props };
  if (propsObj.permissions) {
    let resourcename = `/${props.resource}`;
    let myPermissions = propsObj.permissions.filter(item => item.NavigateUrl.toLowerCase().trim() == resourcename);
    if (myPermissions.length > 0) {
      let p = myPermissions[0];
      propsObj.hasList = (p.View == 1) ? true : false;
      propsObj.hasShow = (p.View == 1) ? true : false;
      propsObj.hasEdit =  true//(p.Edit == 1) ? true : false;
      propsObj.hasDelete = (p.Delete == 1) ? true : false;
      propsObj.hasCreate = true//(p.Create == 1) ? true : false;
      propsObj.hasExport = (p.Export == 1) ? true : false;
    }
  }

  return (
    <List  {...propsObj} exporter={propsObj.hasExport} bulkActionButtons={false} title="Page Contents" filters={<Filters />}  >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' : false}>
        {propsObj.hasEdit && <EditButton />}

        <TextField source="sectionType" label="Section" />
        <TextField source="propertyName" label="Name" />
        <TextField source="propertyLabel" label="Heading" />
        <TextField source="propertyDescription" label="Description" className="description-column" />
        <TextField source="propertyValue" label="Additional Value" />
        <ImageField source="imageUrl" label="Image" />

      </Datagrid>
    </List>
  );
};
