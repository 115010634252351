import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
export const CountryCreate = (props) => {
  let propsObj = Authorizer(props,"Create"); 
  const classes = useStyles({});
  return (
    <Create  {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput source="code"  validate={[required()]} formClassName={classes.one_three_input} />
        <TextInput source="name" validate={[required()]}  formClassName={classes.two_three_input}/>
        <BooleanInput source="isActive" validate={[required()]} initialValue={true} label="Active" formClassName={classes.last_three_input}  />
             
            
        
        </SimpleForm>
    </Create>
  );
};
