import React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  ImageInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageField
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { useStyles } from "../../common/formStyles";
import { Authorizer } from "../../common/Authorizer";
export const LookupCreate = (props) => {
  let propsObj = Authorizer(props, "Create");
  const classes = useStyles({});
  return (
    <Create  {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
        <TextInput source="code" validate={[required()]} fullWidth={true} formClassName={classes.first_inline_input} disabled />
        <TextInput source="name" validate={[required()]} fullWidth={true} formClassName={classes.last_inline_input} disabled />
        <TextInput source="value" validate={[required()]} fullWidth={true} formClassName={classes.one_three_input} />
        <TextInput source="value1" validate={[required()]} fullWidth={true} formClassName={classes.two_three_input} />
        <TextInput source="narration" validate={[required()]} fullWidth={true} formClassName={classes.last_three_input} />
      </SimpleForm>
    </Create>
  );
};
