// src/QuestionList.js
import React, { useState, useEffect } from 'react';
import './QuestionList.css';
import { Button } from '@material-ui/core';
import * as apiClient from "../../common/apiClient";
import { Switch, FormControlLabel, Typography, Box } from '@material-ui/core';


const QuestionList = (props) => {

    useEffect(() => {

        loadQuestions();
    }, []);
    const [questions, setQuestions] = useState([]);
    const [selectedQuestion, setSelectedQuestion] = useState(null);
    const [selectedOptions, setSelectedOptions] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [selectedOptionValue, setSelectedOptionValue] = useState(null);
    const [isLoadingQuestion, setIsLoadingQuestion] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalData, setModalData] = useState({});

    const loadQuestions = async () => {
        setIsLoadingQuestion(true);
        let response = await apiClient.getTestQuestion(props.courseModuleTopicId);
        setQuestions(response);
        setIsLoadingQuestion(false);

    }
    const loadQuestionOption = async (question_id) => {
        setSelectedOptionValue([]);
        setSelectedOptions([]);
        let response = await apiClient.getTestQuestionOption(question_id);
        setSelectedOptions(response); 
    }
    const loadQuestionOptions = async (question_option_id) => {
        setSelectedOptionValue([]);
        let response = await apiClient.getTestQuestionOptions(question_option_id);
        setSelectedOptionValue(response);  
    }

    const handleManageOptions = (event, question) => {
        setSelectedQuestion(question);
        loadQuestionOption(question.id)
    };

 
    const updateOption = async () => {
        for (const optionsElement of selectedOptions)
            await apiClient.updateEntiyRecord("test-question-options", optionsElement.id, optionsElement).then((response) => {
            });
        alert("Options updated")
    };

    const updateOptionValue = async () => {
        for (const optionsElement of selectedOptionValue)
            {
                debugger
                if (optionsElement.id) {
                    await apiClient.updateEntiyRecord("test-question-option-values", optionsElement.id, optionsElement).then((response) => {
                    });
                }
                else {
                    let data = {
                        "subjectId": selectedQuestion.subjectId,
                        "courseId": selectedQuestion.courseId,
                        "courseTopicTypeId": selectedQuestion.courseTopicTypeId,
                        "courseTopicId": selectedQuestion.courseTopicId,
                        "testQuestionId": selectedQuestion.id,
                        "testQuestionOptionId": selectedOption.id,
                        "optionValueText": optionsElement.optionValueText,
                        "isCorrect": optionsElement.isCorrect,
                        "createdBy": 0,
                        "createdDate": "2024-05-19T16:12:08.055Z",
                        "modifyBy": 0,
                        "modifyDate": "2024-05-19T16:12:08.055Z"
                    }
                    await apiClient.addEntiyRecord("test-question-option-values", data).then((response) => {
                    });
                }
            }
            

        alert("Options updated")
    };

    
    const addOptionValue = () => {
        debugger
        if (selectedQuestion && selectedOptionValue) {
            let selected_Options = [...selectedOptionValue];
            selected_Options.push({});
            setSelectedOptionValue(selected_Options);

        }
    };

    const openModal = (question = null) => {
        setModalData(question ? { ...question } : {});
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const handleModalChange = (e) => {
        const { name, value, type, checked } = e.target;
        debugger
        if (name == 'questionTypeId' || name == 'noOfChoice' || name == 'marksPerOption' || name == 'questionNumber' || name == 'questionNumber' || name == 'marks') {

            setModalData({ ...modalData, [name]: type === 'checkbox' ? checked : parseInt(value) });
        }
        else {
            setModalData({ ...modalData, [name]: type === 'checkbox' ? checked : value });
        }

    };

    const handleOptionChange = (e) => {
        debugger
        const { id, name, value, type, checked } = e.target;
        let selected_Option = [...selectedOptions]
        if(type=='checkbox')
            {
                selected_Option[id][name] = checked;
            }
            else
            {
                selected_Option[id][name] = value;
            }
        
        setSelectedOptions(selected_Option);

    };
    const handleOptionChangeCheck = (e,index) => {
        debugger
        const { id, name, value, type, checked } = e.target;
        let selected_Option = [...selectedOptions]
        selected_Option[index]['isCorrect'] = checked;
        
        setSelectedOptions(selected_Option);

    };
    const handleOptionValueChangeCheck = (e,index) => {
        debugger
        const { id, name, value, type, checked } = e.target;
        let selected_Option = [...selectedOptionValue]
        selected_Option[index]['isCorrect'] = checked;
        
        setSelectedOptions(selected_Option);

    };
    const handleOptionValueChange = (e) => {
        const { id, name, value, type, checked } = e.target;
        let selected_Option_value = [...selectedOptionValue]
        selected_Option_value[id][name] = value;
        setSelectedOptionValue(selected_Option_value);

    };
    

    const saveQuestion = async () => {
        if (modalData.id) {
            await apiClient.updateEntiyRecord("test-questions", modalData.id, modalData).then((response) => {
                closeModal();
                loadQuestions();

            });

        } else {

            let data = {

                subjectId: 0,
                courseId: 0,
                courseTopicTypeId: 0,
                courseTopicId: parseInt(props.courseModuleTopicId),
                testPageId: 1,
                questionNumber: parseInt(modalData.questionNumber),
                question: modalData.question,
                questionTips: modalData.questionTips,
                questionTypeId: modalData.questionTypeId,
                noOfChoice: parseInt(modalData.noOfChoice),
                marksPerOption: parseInt(modalData.marksPerOption),
                marks: parseInt(modalData.marks),
                mustAnswer: modalData.mustAnswer,
                "createdBy": 1,
                "createdDate": "2024-01-01T00:00:00.000Z",


            }
            await apiClient.addEntiyRecord("test-questions", data).then((response) => { })
            {
                closeModal();
                loadQuestions();

            }


        }

    };

    return (
        <div className="question-list">

            <div className="header">
                <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                    <div style={{ minWidth: "89%" }}>
                        {selectedQuestion && <div className='question-title'>{selectedQuestion.questionNumber}. {selectedQuestion.question}</div>}
                    </div>
                    <div style={{ minWidth: "8%" }}><Button variant="contained" color="primary"
                        size="medium" onClick={(event) => openModal()}>Add Question</Button>
                    </div></div>

            </div>
            {isLoadingQuestion && <div className="question-grid">Loading Questions....</div>}

            {!isLoadingQuestion && <div className="question-grid">
                <div className='question-header'>Question</div>
                {/* {isLoadingQuestion &&   <div className="loading-spinner">sss</div> } */}
                {questions.map((question, index) => (
                    <div key={question.id} className="question-item">
                        <div className='question-title'>{question.questionNumber}. {question.question}</div>

                        <div ><span className='question-sub-title'>No of Options:</span> {question.noOfChoice}</div>
                        <div ><span className='question-sub-title'>Marks per Option:</span> {question.marksPerOption}</div>
                        <div ><span className='question-sub-title'>Total Marks:</span> {question.marks}</div>
                        <div ><span className='question-sub-title'>Must Answer:</span> {question.mustAnswer ? "Yes" : "No"}</div>
                        <div ><span className='question-sub-title'>Type:</span>
                            {question.questionTypeId == 1 ? "Single Choice" : ""}
                            {question.questionTypeId == 2 ? "Drop Down list" : ""}
                            {question.questionTypeId == 3 ? "Multi Choice" : ""}

                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                            <span className='question-buttons' onClick={(event) => handleManageOptions(event, question)}>View /Edit Options</span>
                            <span className='question-buttons'
                                size="medium" onClick={(event) => { setModalData(question); setIsModalOpen(true) }}>Edit Question</span>
                        </div>
                    </div>

                ))}
            </div>}
            {selectedOptions && selectedOptions && (
                <div className="options-grid">
                    <div className='question-header'>Options</div>
                    {selectedOptions.map((option, index) => (
                        <div key={index} className="option-item">
                            <span className='question-sub-title'>{"Option No. "}{option.optionNumber} </span>
                            <div style={{ display: 'flex', flexDirection: 'row' }}><span className='question-sub-title'>{"Title"}</span><input style={{ width: "100%", height: "30px" }} type="text" id={index} name="optionTitle" value={option.optionTitle} onChange={handleOptionChange} /></div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}><span className='question-sub-title'>{"Text"}</span><input style={{ width: "100%", height: "30px" }} type="text" id={index} name="optionText" value={option.optionText} onChange={handleOptionChange} /></div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>

                                {" "}
                                {selectedQuestion.questionTypeId == 2 &&
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}><span className="question-option-link" onClick={() => { setSelectedOption(option); loadQuestionOptions(option.id) }} >View Option Values</span> </div> 
                                }
                                 {selectedQuestion.questionTypeId != 2 &&
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                                        <Box>
      
      <FormControlLabel
       type="checkbox"
        control={
          <Switch
          type="checkbox"
          checked={option.isCorrect}
          onChange={(e)=>handleOptionChangeCheck(e,index)}
          color="primary"
          />
        }
        label={option.isCorrect ? 'Correct Option' : 'Correct Option'}
      />
    </Box>
           
                                         </div> 
                                }

                            </div>

                        </div>
                    ))}
                    {selectedOptions && selectedOptions.length > 0 && <Button variant="contained" color="primary"
                        size="medium" onClick={updateOption}>Update Options </Button>}
                </div>
            )}
            {selectedQuestion && selectedQuestion.questionTypeId == 2 && selectedOption && selectedOptionValue && (
                <div className="option-values-grid">
                    <div className='question-header'>Option Value</div>
                    {selectedOptionValue.map((item, index) => (
                        <div key={index} className="option-item">
                            <span className='question-sub-title'>{"Option No. "}{index + 1} </span>
                            <div style={{ display: 'flex', flexDirection: 'row' }}><span className='question-sub-title'>{"List Item"}</span><input style={{ width: "100%", height: "30px" }} type="text" id={index} name="optionValueText" value={item.optionValueText} onChange={handleOptionValueChange} /></div>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Box>
      
      <FormControlLabel
       type="checkbox"
        control={
          <Switch
          type="checkbox"
          checked={item.isCorrect}
          onChange={(e)=>handleOptionValueChangeCheck(e,index)}
          color="primary"
          />
        }
        label={item.isCorrect ? 'Correct Option' : 'Correct Option'}
      />
    </Box>
                            </div>
                            
                        </div>
                    ))}
                 
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', justifyContent: 'flex-end' }}>
                    <span className='question-buttons' onClick={addOptionValue} >Add Row</span> 
                        {selectedOptionValue && selectedOptionValue.length > 0 && <span className='question-buttons' onClick={updateOptionValue} >Update Options</span> }
                        </div> 

                </div>
            )}
            {isModalOpen && (
                <div className="modal">
                    <div className="modal-content">
                        <h3>{modalData.id ? 'Edit Question' : 'Add Question'}</h3>


                        <label>
                            Question Number:
                            <input type="number" name="questionNumber" value={modalData.questionNumber} onChange={handleModalChange} />
                        </label>
                        <label>
                            Question Text:
                            <input type="text" name="question" value={modalData.question} onChange={handleModalChange} />
                        </label>
                        <label>
                            Question Tips:
                            <input type="text" name="questionTips" value={modalData.questionTips} onChange={handleModalChange} />
                        </label>
                        <label>
                            No of Options:
                            <input type="number" name="noOfChoice" value={modalData.noOfChoice} onChange={handleModalChange} />
                        </label>
                        <label>
                            Marks per Option:
                            <input type="number" name="marksPerOption" value={modalData.marksPerOption} onChange={handleModalChange} />
                        </label>
                        <label>
                            Total Marks:
                            <input type="number" name="marks" value={modalData.marks} onChange={handleModalChange} />
                        </label>
                        <label>
                            Must Answer:
                            <input type="checkbox" name="mustAnswer" checked={modalData.mustAnswer} onChange={handleModalChange} />
                        </label>
                        <label>
                            Question Type:
                            <select name="questionTypeId" style={{ "height": "30px" }} value={modalData.questionTypeId} onChange={handleModalChange}>
                                <option value={1}>Single Choice</option>
                                <option value={2}>Drop Down list</option>
                                <option value={3}>Multi Choice</option>


                            </select>
                        </label>
                        <Button variant="contained" color="primary"
                            size="medium" onClick={() => saveQuestion()}>{modalData.id ? 'Save' : 'Add'}</Button>
                        <Button variant="contained" color="primary"
                            size="medium" onClick={closeModal}>Cancel</Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default QuestionList;
