import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  FormDataConsumer,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict"
import { Authorizer } from "../../common/Authorizer";
export const CityCreate = (props) => {
  let propsObj = Authorizer(props,"Create");
  return (
    <Create  {...propsObj}>
      <SimpleForm
        redirect="list"
        toolbar={<FormToolbar {...props} showDelete={false} />}
        variant="outlined"
      >
             {/* <FormDataConsumer>
              {formDataProps => (
                <StateDistrict {...formDataProps} />
              )}
            </FormDataConsumer> */}
            <ReferenceInput variant="outlined" label="District" source="districtId"
                reference="districts" perPage={5000}
                // filter={{ "stateId": formData.stateId }}
                validate={required()}
                fullWidth={true} >
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>
        <TextInput source="code" fullWidth={true} validate={[required()]} />
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        
                    
        <BooleanInput
          source="isActive"
          validate={[required()]}
          label="Active"
          initialValue={true}
        />
      </SimpleForm>
    </Create>
  );
};
