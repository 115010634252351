import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput,
  SelectInput
} from "react-admin";

import { Authorizer } from "../../common/Authorizer";
import CDeleteButton from "../../common/CDeleteButton";

export const ConfigsList = (props) => {
  let propsObj = Authorizer(props,"List"); 
  return (
    <List {...propsObj} exporter={propsObj.hasExport}   >
      <Datagrid rowClick={propsObj.hasEdit ? 'edit' :  false}>
        {propsObj.hasEdit && <EditButton />}
        {propsObj.hasDelete && <CDeleteButton {...props}/>}
        <TextField source="mconame" label="Company Name" />
        <TextField source="add1" label="Address1" />
        <TextField source="add2" label="Address2" />
        <BooleanField source="isActive" label="Active"/>
         <ReferenceField source="createdBy" reference="users" label="Created By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="createdDate" label="Created Date" locales="fr-FR" />
            <ReferenceField source="modifyBy" reference="users" label="Updated By"  link={false}>
                <TextField source="userName" />
            </ReferenceField>
            <DateField  source="modifydate" label="Updated Date" locales="fr-FR" />
            </Datagrid>      
    </List>
  );
};
