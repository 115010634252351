import React from "react";

import {
  List,
  Datagrid,
  TextField,
  DateField,
  ReferenceField,
  NumberField,
  BooleanField,
  EditButton,
  DeleteButton,
  ImageField,
  Filter,
  ReferenceInput,
  SearchInput
} from "react-admin";
import { DisplayDateTime } from "../../common/DisplayDate";
import { Authorizer } from "../../common/Authorizer";


const Filters = props => (
    <Filter {...props} variant="outlined">
      <SearchInput variant="outlined" label="Code" source="code~like" alwaysOn />
        <SearchInput variant="outlined" label="Name" source="name~like" alwaysOn />
        <SearchInput variant="outlined" label="Value" source="value~like" alwaysOn />
    </Filter>
);
export const LookupList = (props) => {
  let propsObj = Authorizer(props,"List"); 
  return (
    <List  {...propsObj} exporter={propsObj.hasExport}  filter={ { isModByUser: 1 } }  filters={<Filters />}  >
      <Datagrid rowClick={false} >
        
        {propsObj.hasEdit && <EditButton />}
        <TextField source="code" label="Code" />
        <TextField source="name" label="Name" />
        <TextField source="value" label="Value" />
        <TextField source="Narration" label="Narration" />
        <TextField source="value1" label="Value1" />
      </Datagrid>
    </List>
  );
};

