import React from 'react';
import {
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices,
    Create, SimpleForm, TextInput, DateInput,
    ImageInput, ImageField, ReferenceInput, SelectInput,
    BooleanInput, NumberInput,FormDataConsumer
} from 'react-admin';
import { useStyles } from "../../formStyles";

import { parse } from "query-string";
import {CreateToolbar} from "../../common/CreateToolbar";
import { CourseFields } from './CourseFields';


export const QuestionCreate = props => {
    const classes = useStyles({});
    const { test_id: test_id_string } = parse(props.location.search);
    const test_id = test_id_string ? parseInt(test_id_string, 10) : "";

    const redirect = test_id ? `/tests/${test_id}/1` : "show";
    return (
        <Create {...props} title="Add new question">
            <SimpleForm variant="outlined"  toolbar={<CreateToolbar {...props}/>}
            defaultValue={{ testid:test_id }}
            redirect={redirect}
            >
                    <ReferenceInput label="Subject" source="subjectId" validate={required()} reference="subjects" fullWidth={true} >
                    <SelectInput optionText="subjects" />
                </ReferenceInput>
                <FormDataConsumer fullWidth={true} >
              {formDataProps => (
                <CourseFields {...formDataProps} />
              )}
            </FormDataConsumer>
        
                
            </SimpleForm>
        </Create>
    );
}