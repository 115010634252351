import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  FormDataConsumer,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { StateDistrict } from "./StateDistrict"
import { Authorizer } from "../../common/Authorizer";
export const CityEdit = (props) => {
  let propsObj = Authorizer(props,"Edit");
  return (
    <Edit  {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="code" fullWidth={true} validate={[required()]} disabled/>
        <TextInput source="name" fullWidth={true} validate={[required()]} />
      <ReferenceInput variant="outlined" label="District" source="districtId"
                reference="districts" perPage={5000}
                // filter={{ "stateId": formData.stateId }}
                validate={required()}
                fullWidth={true} >
                <SelectInput validate={required()} optionText="name" />
            </ReferenceInput>
        
        
                    
        <BooleanInput
          source="isActive"
          validate={[required()]}
          label="Active"
          initialValue={true}
        />
        </SimpleForm>
    </Edit>
  );
};
