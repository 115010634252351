import React from "react";
import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,ImageInput,
  ImageField
} from "react-admin";
import RichTextInput from 'ra-input-rich-text';
import { FormToolbar } from "../../common/FormToolbar";

let defaultValues = {
  isHidden: false,
  isActive:false
};
export const PageContentCreate = (props) => {
  return (
    <Create title="Web Content Create" undoable={false} {...props}>
      <SimpleForm redirect="list"
      initialValues={defaultValues}
       toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
      <TextInput source="sectionType" label="Section" fullWidth={true} validate={[required()]} />
        <TextInput source="propertyName" label="Name" fullWidth={true}/>
        <TextInput source="propertyLabel" lable="Heading" fullWidth={true}/>
        <RichTextInput source="propertyDescription" fullWidth={true} />
        <TextInput source="propertyValue" label="Additional Value" fullWidth={true}/>
        
       
        <ImageInput source="imageUrlup" 
                            label="Icon File" accept="image/*" className="logourl">
                            <ImageField source="imageUrlup" title="Image" />
                        </ImageInput>
                        
       
       <ImageField source="imageUrl" title="Image"/>
        
      </SimpleForm>
    </Create>
  );
};
