import React from "react";
import { JsonSchemaForm } from "react-jsonschema-form";

import {
  Edit,
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput,
  ImageInput,
  ImageField,DateInput
} from "react-admin";

import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";
import RichTextInput from 'ra-input-rich-text';
export const ActivityCreate = (props) => {
  const classes = useStyles({});
  let propsObj = Authorizer(props,"Create"); 
  
  let userID = localStorage.getItem("userId");
  let defaultValues = {
    createdDate: new Date(),
    createdBy: userID,
    isActive: true,
    viewCount:0
  };
  return (
    <Create {...propsObj}> 
      <SimpleForm redirect="list" initialValues={defaultValues}
        toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" > 
         
       <TextInput source="name" label="Name"  fullWidth={true}  validate={[required()]} formClassName={classes.first_inline_input}/>
       <TextInput source="category" label="Category"  fullWidth={true}  validate={[required()]} formClassName={classes.last_inline_input}/>  
       <TextInput source="shortText" label="Short Description"  multiline fullWidth={true}  validate={[required()]} /> 

       <RichTextInput source="postContent" label="Blog Content" />
       
       <div>
       { <ImageInput source="imageUrlup" formClassName={classes.one_4_input}
                            label="Icon File" accept="image/*" className="logourl">
                            <ImageField source="imageUrlup" title="Image" />
                        </ImageInput>}
                        
       </div>
       <ImageField source="imageUrl" title="Image" formClassName={classes.twice_4_input}/>
       <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} formClassName={classes.last_4_input}/>
      </SimpleForm>
    </Create>
  );
};
