import React from "react";
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  required,
  BooleanInput
} from "react-admin";
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
export const StateEdit = (props) => {
  const propsObj = Authorizer(props,"Edit");
  return (
    <Edit  {...propsObj}>
      <SimpleForm toolbar={<FormToolbar {...props} />} variant="outlined">
      <TextInput source="code" fullWidth={true} validate={[required()]}  disabled/>
        <TextInput source="name" fullWidth={true} validate={[required()]} />
        
      <ReferenceInput source="countryId" reference="countries"  fullWidth={true} link={false} label="Country" >
                        <SelectInput source="name" />
                    </ReferenceInput>
        
        <BooleanInput source="isActive" validate={[required()]} label="Active" initialValue={true}/>
        </SimpleForm>
    </Edit>
  );
};
