import React, { useState, useEffect, useCallback } from 'react';
import { useMediaQuery } from '@material-ui/core';
import * as apiClient from "./../common/apiClient";
import CountBlock from "./CountBlock";
import {
    useNotify
} from "react-admin";
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { makeStyles } from '@material-ui/core/styles';
import { Drawer } from '@material-ui/core';

 
const useStyles = makeStyles({
    flexCenter: { display: 'flex', justifyContent: "center", alignContent: "center", flexWrap: "wrap" },
    flex: { display: 'flex' },
    flexColumn: { display: 'flex', flexDirection: 'column' },
    leftCol: { flex: 1, marginRight: '1em' },
    rightCol: { flex: 1, marginLeft: '1em' },
    singleCol: { marginTop: '2em', marginBottom: '2em' },
});

const Dashboard = () => { 
    const [db, setDB] = useState([]); 
    const styles = useStyles({});
    const notify = useNotify();  
    useEffect(() => {
        getDashboard();
    }, []);
    const getDashboard = () => {
        apiClient.get_dashboard().then(res => { 
            setDB(res);
        }).catch(err => {
            console.log(err);
        });
    }

   
    

    return (
        <div>
            <div className={styles.flexCenter}>
                {db && db.map((item: any, index: number) => {
                    return (
                        <div key={index}>
                            <CountBlock title={item.DashboardType} value={item.TotalCount} icon={item.IconUrl} />
                        </div>
                    )
                })}
            </div>

             
        </div>
    );
};

export default Dashboard;
