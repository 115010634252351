import React, { useState, useEffect, useCallback } from 'react';
import {
  required, minLength, maxLength, minValue, maxValue, useRefresh,
  number, regex, email, choices,
  TabbedForm, FormTab, ReferenceInput,
  Edit, SimpleForm, TextInput, RichTextField, FileInput, FileField,
  SelectInput, EditButton, NumberField, DateField,
  DateInput, BooleanInput, NumberInput, ImageInput, ImageField, FormDataConsumer,
  ReferenceManyField, ReferenceField, Datagrid, TextField, BooleanField, List, Filter, SearchInput, AutocompleteInput
} from 'react-admin';
import { FormToolbar } from "../../common/FormToolbar";
import { Authorizer } from "../../common/Authorizer";
import { useStyles } from "../../common/formStyles";
import Button from '@material-ui/core/Button';
import CDeleteButton from "../../common//CDeleteButton";
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';

import { makeStyles } from '@material-ui/core/styles';
import ChatBubbleIcon from "@material-ui/icons/ChatBubble";


import { Drawer } from '@material-ui/core';
import { Route, useRouteMatch, useLocation } from 'react-router-dom';
import { ModuleCreate } from './ModuleCreate';
import ModuleEdit from './ModuleEdit';
import { ModuleTopicTypeCreate } from './ModuleTopicTypeCreate';
import ModuleTopicTypeEdit from './ModuleTopicTypeEdit';
import { ModuleTopicCreate } from './ModuleTopicCreate';
import ModuleTopicEdit from './ModuleTopicEdit';
import { ModuleChapterTopicTypeCreate } from './ModuleChapterTopicTypeCreate';
import ModuleChapterTopicTypeEdit from './ModuleChapterTopicTypeEdit';
import ModuleChapterEdit from './ModuleChapterEdit';
import { ModuleChapterCreate } from './ModuleChapterCreate';
import ModuleChapterFileEdit from './ModuleChapterFileEdit';
import { ModuleChapterFileCreate } from './ModuleChapterFileCreate';
import Question from '../question';
import { QuestionModule } from './QuestionModule';
const myStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
  },
  list: {
    flexGrow: 1,
    transition: theme.transitions.create(['all'], {
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: 0,
  },
  listWithDrawer: {
    marginRight: 300,
  },
  drawerPaper: {
    zIndex: 100,
    marginTop: 50,
    width: '90%',
  },
}));

export const ActivityEdit = (props) => {

  const [isAddModule, setIsAddMoudle] = useState(false);
  const [isEditModule, setIsEditModule] = useState(false);
  const [IsEditRecord, setIsEditRecord] = useState<any>(null);
  const [isEditModuleTopicType, setIsEditModuleTopicType] = useState(false);
  const [isAddModuleTopicType, setIsAddModuleTopicType] = useState(false);
  const [isEditModuleTopic, setIsEditModuleTopic] = useState(false);
  const [isAddModuleTopic, setIsAddModuleTopic] = useState(false);
  const [isAddModuleChapterTopicType, setIsAddModuleChapterTopicType] = useState(false);
  const [isEditQustion ,setIsEditQustion] = useState(false);
  
  const [isEditModuleChapterTopicType, setIsEditModuleChapterTopicType] = useState(false);

  const [isAddChapter, setIsAddChapter] = useState(false);
  const [isEditChapter, setIsEditChapter] = useState(false);


  const [isAddChapterFile, setIsAddChapterFile] = useState(false);
  const [isEditChapterFile, setIsEditChapterFile] = useState(false);


  const classes = useStyles({});
  const refresh = useRefresh();
  const myclasses = myStyles({});
  let propsObj = Authorizer(props, "Edit");
  const onCloseForm = () => {
    setIsAddMoudle(false)
    setIsEditModule(false)
    setIsAddModuleTopicType(false)
    setIsEditModuleTopicType(false)
    setIsAddModuleTopic(false)
    setIsEditModuleTopic(false)
    setIsEditModuleChapterTopicType(false);
    setIsAddModuleChapterTopicType(false);
    setIsAddChapter(false);
    setIsEditChapter(false);
    setIsAddChapterFile(false);
    setIsEditChapterFile(false);
    setIsEditQustion(false);
    refresh();
  }
  const onCancel = () => {
    setIsAddMoudle(false)
    setIsEditModule(false)
    setIsAddModuleTopicType(false)
    setIsEditModuleTopicType(false)
    setIsAddModuleTopic(false)
    setIsEditModuleTopic(false)
    setIsEditModuleChapterTopicType(false);
    setIsAddModuleChapterTopicType(false);
    setIsAddChapter(false);
    setIsEditChapter(false);
    setIsAddChapterFile(false);
    setIsEditChapterFile(false);
    setIsEditQustion(false);
  }

  const CustomEdit = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<EditIcon />}>Edit</Button>
  }

  const CustomAddButton = (props) => {
    let { record } = props;
    return <Button
      color="secondary" disableElevation
      size="medium"

      onClick={() => {
        props.onClick(record);
      }}
      startIcon={<AddIcon />}>{props.label}</Button>
  }

  
  const QuestionButton = (props) => {
    let { record } = props;
    let isQuiz=record.isQuiz;
    return <div>{isQuiz?<Button
      color="secondary" disableElevation
      size="medium"
      

      onClick={() => {
        props.onClick(record);
      }}
      >{props.label}</Button>:""}</div>
    
  
  }

  const ModuleFilter = (props) => (


    <Filter {...props}>
      <SearchInput variant="outlined" label={props.nameLabel} source="name~like"  />
      <SearchInput variant="outlined" label=" Code" source="code~like" />

      <ReferenceInput alwaysOn label="Module" variant="outlined" source="courseModuleId" reference="course-modules" filter={{ courseId: props.courseId ? props.courseId : 0 }} sort={{ field: 'name', order: 'asc' }}  fullWidth={true}  > 
        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />
      </ReferenceInput>

      {props.showTopicType && <ReferenceInput  alwaysOn label="Topic Group" variant="outlined" source="courseModuleTopicTypeId" reference="course-module-topic-types" filter={{ courseId: props.courseId ? props.courseId : 0 }} sort={{ field: 'name', order: 'asc' }} fullWidth={true} >
        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} /> 
      </ReferenceInput>}

      {props.showTopic && <ReferenceInput  alwaysOn label="Topic" variant="outlined" source="courseModuleTopicId" reference="course-module-topics" filter={{ courseId: props.courseId ? props.courseId : 0 }} sort={{ field: 'name', order: 'asc' }} fullWidth={true} >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />          
      </ReferenceInput>}

      {props.showChapterType && <ReferenceInput alwaysOn label="Chapter Group" variant="outlined" source="courseModuleTopicChapterTypeId" reference="course-module-topic-chapter-types" filter={{ courseId: props.courseId ? props.courseId : 0 }} sort={{ field: 'name', order: 'asc' }}  fullWidth={true} >
        <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />  
      </ReferenceInput>}

      {props.showChapter && <ReferenceInput label="Chapter " alwaysOn variant="outlined" source="courseModuleTopicChapterId" reference="course-module-topic-chapter-types" filter={{ courseId: props.courseId ? props.courseId : 0 }} sort={{ field: 'name', order: 'asc' }}  fullWidth={true} >
      <AutocompleteInput optionText="name" shouldRenderSuggestions={(val) => { return val && val.trim().length >= 1 }} />  
      </ReferenceInput>}
 
    </Filter>

  );
  return (
    <>
      <Edit title="Course Module Edit" {...propsObj}  >


        <TabbedForm redirect="list" title="Course Module Edit" toolbar={<FormToolbar {...props} showDelete={false} />} variant="outlined" >
          <FormTab value="DETAILS" label="Course Detail">
            <ReferenceInput label="Subject" source="subjectId" fullWidth={true} reference="subjects" >
              <SelectInput optionText="subjects" validate={[required()]} />
            </ReferenceInput>
            <TextInput source="code" label="Code" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="name" label="Name" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="description" label="Description" multiline fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="author" label="Author" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="support" label="Support" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="about" label="About" multiline fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />



            <TextInput source="title" label="Title" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="publName" label="Publisher" fullWidth={true} validate={[required()]} formClassName={classes.two_three_input} />
            <TextInput source="edition" label="Edition" fullWidth={true} validate={[required()]} formClassName={classes.last_three_input} />

            <TextInput source="copyYear" label="Year" fullWidth={true} validate={[required()]} formClassName={classes.one_three_input} />
            <TextInput source="requirements" label="Requirements"   fullWidth={true}  validate={[required()]} formClassName={classes.two_three_input}/>  
            <BooleanInput source="isActive" validate={[required()]}  label="Active" initialValue={true} formClassName={classes.last_three_input} />  
            <div>
              {<ImageInput source="iconFileup" formClassName={classes.one_4_input}
                label="Icon File" accept="image/*" className="logourl">
                <ImageField source="iconFileup" title="Icon" />
              </ImageInput>}

            </div>
            <ImageField source="iconFile" title="Icon" />
          </FormTab>
          <FormTab value="DETAILS" label="Modules">
            {<Button variant="contained" onClick={() => { setIsAddMoudle(true); }}
              color="secondary">
              Add Module
            </Button>}
            <ReferenceManyField perPage={100} reference="course-modules" target="courseId" addLabel={false} fullWidth={true}>
              <Datagrid rowClick={false}>
                <CDeleteButton {...props} />
                <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditModule(true); }} label="Edit" />
                <TextField source="displayOrder" label="Display Order" />
                <TextField source="code" label="Code" />
                <TextField source="name" label="Module" />

              </Datagrid>
            </ReferenceManyField>
          </FormTab>

          <FormTab value="DETAILS" label="Topic Group">
            {<Button variant="contained" onClick={() => { setIsAddModuleTopicType(true) }}
              color="secondary">
              Add Topic Group
            </Button>}
            <ReferenceManyField perPage={1000} reference="course-module-topic-types" target="courseId" addLabel={false} fullWidth={true}>
              <List {...props}
                title=" "
                perPage={1000}
                pagination={null}
                bulkActionButtons={false}
                exporter={false}
                hasCreate={false}
                
                reference="course-module-topic-types"
                filter={{ courseId: props.id }}
                sort={{ field: "displayOrder", order: "DESC" }}
                filters={<ModuleFilter courseId={props.id} nameLabel={"Topic Group"} />} // Add the filter prop here
              >
                <Datagrid rowClick={false}>
                  <CDeleteButton {...props} />
                  <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditModuleTopicType(true); }} label="Edit" />
                  <CustomAddButton onClick={(record) => { setIsEditRecord(record); setIsAddModuleTopic(true); }} label="Add Topic" />
                  <ReferenceField source="courseModuleId" reference="course-modules" label="Module" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="displayOrder" label="Display Order" />
                  <TextField source="code" label="Code" />
                  <TextField source="name" label="Topic Group" />

                </Datagrid>
              </List>

            </ReferenceManyField>
          </FormTab>

          <FormTab value="DETAILS" label="Topics">
            {<Button variant="contained" onClick={() => { setIsAddModuleTopic(true);; setIsEditRecord(null) }}
              color="secondary">
              Add Topic
            </Button>}
            <ReferenceManyField perPage={1000} reference="course-module-topics" target="courseId" addLabel={false} fullWidth={true}>
              <List title=" " {...props}

perPage={1000}
                pagination={null}
                bulkActionButtons={false}
                exporter={false}
                hasCreate={false}
                reference="course-module-topics"
                filter={{ courseId: props.id }}
                sort={{ field: "displayOrder", order: "DESC" }}
                filters={<ModuleFilter showTopicType={true} courseId={props.id} nameLabel={"Topic"} />} // Add the filter prop here

              >
                <Datagrid rowClick={false}>
                  <CDeleteButton {...props} />
                  <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditModuleTopic(true); }} label="Edit" />
                  <CustomAddButton onClick={(record) => { setIsEditRecord(record); setIsAddModuleChapterTopicType(true); }} label="Chapter Type" />
                  <QuestionButton  onClick={(record) => { setIsEditRecord(record); setIsEditQustion(true); }} label="Quiz" />
                  
                  <ReferenceField source="courseModuleId" reference="course-modules" label="Module" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicTypeId" reference="course-module-topic-types" label="Topic Group" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="displayOrder" label="Display Order" />
                  <TextField source="code" label="Code" />
                  <TextField source="name" label="Topic " />
                </Datagrid>
              </List>
            </ReferenceManyField>
          </FormTab>

          <FormTab value="DETAILS" label="Chapter Group">
            {<Button variant="contained" onClick={() => { setIsAddModuleChapterTopicType(true);; setIsEditRecord(null) }}
              color="secondary">
              Add Chapter Group
            </Button>}
            <ReferenceManyField perPage={1000} reference="course-module-topic-chapter-types" target="courseId" addLabel={false} fullWidth={true}>
              <List title=" " {...props}

perPage={1000}
pagination={null}
                bulkActionButtons={false}
                exporter={false}
                hasCreate={false}
                reference="course-module-topic-chapter-types"
                filter={{ courseId: props.id }}
                sort={{ field: "displayOrder", order: "DESC" }}
                filters={<ModuleFilter showTopicType={true} showTopic={true} courseId={props.id} nameLabel={"Chapter Group"} />} // Add the filter prop here
              >
                <Datagrid rowClick={false}>
                  <CDeleteButton {...props} />
                  <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditModuleChapterTopicType(true); }} label="Edit" />
                  <CustomAddButton onClick={(record) => { setIsEditRecord(record); setIsAddChapter(true); }} label="Chapter " />
                  <ReferenceField source="courseModuleId" reference="course-modules" label="Module" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicTypeId" reference="course-module-topic-types" label="Topic Type" link={false}>
                    <TextField source="name" />
                  </ReferenceField>


                  <ReferenceField source="courseModuleTopicId" reference="course-module-topics" label="Topic" link={false}>
                    <TextField source="name" />
                  </ReferenceField>

                  <TextField source="displayOrder" label="Display Order" />
                  <TextField source="code" label="Code" />
                  <TextField source="name" label="Chapter Group " />

                </Datagrid>
              </List>
            </ReferenceManyField>
          </FormTab>

          <FormTab value="DETAILS" label="Chapters">
            {<Button variant="contained" onClick={() => { setIsAddChapter(true);; setIsEditRecord(null) }}
              color="secondary">
              Add Chapter
            </Button>}
            <ReferenceManyField perPage={1000} reference="course-module-topic-chapters" target="courseId" addLabel={false} fullWidth={true}>
              
              <List {...props}
                title=" "
                perPage={1000}
                pagination={null}
                bulkActionButtons={false}
                exporter={false}
                hasCreate={false}
                reference="course-module-topic-chapters" 
                filter={{ courseId: props.id }}
                sort={{ field: "displayOrder", order: "DESC" }}
                 
                
                filters={<ModuleFilter showTopicType={true} showTopic={true} showChapterType={true} courseId={props.id} nameLabel={"Chapter "} />} // Add the filter prop here
              >
                <Datagrid rowClick={false} id={"ABC"}> 
                  <CDeleteButton {...props} />
                  <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditChapter(true); }} label="Edit" />
                  <CustomAddButton onClick={(record) => { setIsEditRecord(record); setIsAddChapter(true); }} label=" File" />
                  <ReferenceField source="courseModuleId" reference="course-modules" label="Module" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicTypeId" reference="course-module-topic-types" label="Topic Type" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicId" reference="course-module-topics" label="Topic" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicChapterTypeId" reference="course-module-topic-chapter-types" label="Chapter Group" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="displayOrder" label="Display Order" />
                  <TextField source="code" label="Code" />
                  <TextField source="name" label="Chapter " />
                </Datagrid>
              </List>
              
            </ReferenceManyField>
          </FormTab>



          <FormTab value="DETAILS" label="Chapter Files">
            {<Button variant="contained" onClick={() => { setIsAddChapterFile(true);; setIsEditRecord(null) }}
              color="secondary">
              Add Chapter File
            </Button>}

            <ReferenceManyField perPage={1000} reference="course-module-topic-chapter-files" target="courseId" addLabel={false} fullWidth={true}>
              <List {...props}
                title=" "
                perPage={1000}
                pagination={null}
                bulkActionButtons={false}
                exporter={false}
                hasCreate={false}
                reference="course-module-topic-chapters"
                filter={{ courseId: props.id }}
                sort={{ field: "displayOrder", order: "DESC" }}
                filters={<ModuleFilter showTopicType={true} showTopic={true} showChapterType={true} courseId={props.id} showChapter={true} nameLabel={"Chapter File"} />} // Add the filter prop here
              >
                <Datagrid rowClick={false}>
                  <CDeleteButton {...props} />
                  <CustomEdit onClick={(record) => { setIsEditRecord(record); setIsEditChapterFile(true); }} label="Edit" />
                  <ReferenceField source="courseModuleId" reference="course-modules" label="Module" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicTypeId" reference="course-module-topic-types" label="Topic Group" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicId" reference="course-module-topics" label="Topic" link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <ReferenceField source="courseModuleTopicChapterId" reference="course-module-topic-chapters" label="Chapter " link={false}>
                    <TextField source="name" />
                  </ReferenceField>
                  <TextField source="displayOrder" label="Display Order" />
                  <TextField source="code" label="Code" />
                  <TextField source="name" label="Chapter File " />
                </Datagrid>
              </List>

            </ReferenceManyField>
          </FormTab>

        </TabbedForm>


      </Edit>
      <Drawer
        anchor="right"
        onClose={onCancel}
        classes={{
          paper: myclasses.drawerPaper,
        }}
        open={isAddModule
          || isEditModule
          || isAddModuleTopicType
          || isEditModuleTopicType
          || isAddModuleTopic
          || isEditModuleTopic
          || isAddModuleChapterTopicType
          || isEditModuleChapterTopicType
          || isAddChapter
          || isEditChapter
          || isAddChapterFile
          || isEditChapterFile
          || isEditQustion
        } >
        {isAddModule &&
          <ModuleCreate
            onClose={onCloseForm}
            onCancel={onCancel}
            courseId={props.id}
            {...props} />}

        {isEditModule &&
          <ModuleEdit
            onClose={onCloseForm}
            onCancel={onCancel}
            record={IsEditRecord}
            {...props} />}
        {isAddModuleTopicType && <ModuleTopicTypeCreate
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          {...props} />}

        {isEditModuleTopicType && <ModuleTopicTypeEdit
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          record={IsEditRecord}
          {...props} />}



        {isAddModuleTopic && <ModuleTopicCreate
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          courseModuleId={IsEditRecord?.courseModuleId}
          courseModuleTopicTypeId={IsEditRecord?.id}
          {...props} />}

        {isEditModuleTopic && <ModuleTopicEdit
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          record={IsEditRecord}
          {...props} />}
        {isAddModuleChapterTopicType && <ModuleChapterTopicTypeCreate
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          courseModuleId={IsEditRecord?.courseModuleId}
          courseModuleTopicTypeId={IsEditRecord?.courseModuleTopicTypeId}
          courseModuleTopicId={IsEditRecord?.id}

          {...props} />}

        {isEditModuleChapterTopicType && <ModuleChapterTopicTypeEdit
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          record={IsEditRecord}
          {...props} />}


        {isAddChapter && <ModuleChapterCreate
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          courseModuleId={IsEditRecord?.courseModuleId}
          courseModuleTopicTypeId={IsEditRecord?.courseModuleTopicTypeId}
          courseModuleTopicId={IsEditRecord?.courseModuleTopicId}
          courseModuleTopicChapterTypeId={IsEditRecord?.id}
          {...props} />}

        {isEditChapter && <ModuleChapterEdit
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          record={IsEditRecord}
          {...props} />}


        {isAddChapterFile && <ModuleChapterFileCreate
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          courseModuleId={IsEditRecord?.courseModuleId}
          courseModuleTopicTypeId={IsEditRecord?.courseModuleTopicTypeId}
          courseModuleTopicId={IsEditRecord?.courseModuleTopicId}
          courseModuleTopicChapterTypeId={IsEditRecord?.courseModuleTopicChapterTypeId}
          courseModuleTopicChapterId={IsEditRecord?.id}
          {...props} />}

{isEditQustion && <QuestionModule
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          record={IsEditRecord}
          {...props} />}



        {isEditChapterFile && <ModuleChapterFileEdit
          onClose={onCloseForm}
          onCancel={onCancel}
          courseId={props.id}
          record={IsEditRecord}
          {...props} />}


      </Drawer>
    </>
  );
};
