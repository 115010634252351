import {ActivityList} from "./ActivityList";
import {ActivityEdit} from "./ActivityEdit";
import {ActivityShow} from "./ActivityShow";
import {ActivityCreate} from "./ActivityCreate";

export default {
    list: ActivityList,
   create: ActivityCreate,
    edit: ActivityEdit,
    show:ActivityShow
};
