import { AuthProvider } from 'ra-core';
import * as apiClient from "../common/apiClient";

const authProvider: AuthProvider = {
    login: ({ username }) => {
        localStorage.setItem('username', username);
        // accept all username/password combinations
        return Promise.resolve();
    },
    logout: () => {
        let stationid = localStorage.getItem("StationId");
        let userid = localStorage.getItem("userId");
        apiClient.updateUserLogs(userid, stationid, "logout").then(res => {
            console.log(res);
        }).catch(err => {
            console.log(err);
        });
        localStorage.removeItem('username');
        return Promise.resolve();
    },
    checkError: () => Promise.resolve(),
    checkAuth: () =>
        localStorage.getItem('username') ? Promise.resolve() : Promise.reject({ message: 'login.required' }),
    getPermissions: (props) => {
        let per = localStorage.getItem("menu");
        if(per){
            per = JSON.parse(per);
        }
        return per ? Promise.resolve(per) : Promise.reject();
    },
};

export default authProvider;
