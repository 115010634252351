import React, { useCallback } from 'react';
import {
    useRefresh,
    required,
    minLength,
    maxLength,
    minValue,
    maxValue,
    number,
    regex,
    email,
    choices, Button,
    Edit, SimpleForm, TextInput,
    DateInput, BooleanInput, NumberInput,
    ImageInput, ImageField, SaveButton, Toolbar
} from 'react-admin';
import RichTextInput from 'ra-input-rich-text';
import { useForm } from 'react-final-form';
import CancelIcon from '@material-ui/icons/Cancel';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import * as apiClient from "./apiClient";
import { ToastContainer, toast } from 'react-toastify';

const DeleteButton = props => {
    const refresh = useRefresh();
    //console.log("Delete : ");
    //console.log(props);
    const deleteRecord = (event) => {
        var userResponse = window.confirm("Are you sure, you want to delete the record?\nPress Ok to delete");
        if(!userResponse)
            return;
        event.stopPropagation();
        apiClient.deleteRecord(props.resource, props.record.id).then(res => {
            if(res.error){
                toast.error("Validation issue : " + res.error.message, {
                    position: "bottom-center",
                    autoClose: 3000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    });
            }else{
                refresh();
            }
            
        }).catch(err => {
            console.log(err);
        });
    }
    return (
            <Button
                 color="secondary" disableElevation
                size="medium"
                redirect={false}
                onClick={deleteRecord}
                startIcon={<DeleteIcon />}
            />
    )
};
export default DeleteButton;